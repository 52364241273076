var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c01709d52b4a885de2e22c9cd73649dda83d825d"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SentrySeverity = {
  Info: "info",
  Warning: "warning",
  Error: "error",
  Critical: "fatal",
  Log: "log",
};
Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
  performance: false,
  beforeSend(event) {
    if (event.request) {
      const { method, url, status_code } = event.request;
      // Define the status codes you want to filter
      const filteredStatusCodes = [400, 401, 403, 404, 408, 429];
      // Check if the status code is in the filtered list
      if (filteredStatusCodes.includes(status_code)) {
        // You can optionally add more context or modify the event here
        event.message = `HTTP ${status_code} Error: ${method} ${url}`;
        event.level = SentrySeverity.Error; // Change the severity level if needed
      }
    }

    return event;
  },
  ignoreErrors: [
    // Random plugins/extensions
    "TypeError: NetworkError when attempting to fetch resource.",
    "top.GLOBALS",
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    "fb_xd_fragment",
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    "conduitPage",
  ],
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
